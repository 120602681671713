<template>
  <div class="main analytics-page">
    <div class="main-width">
      <div class="news" v-if="news">
        <h2 class="text-center">{{ news.titleRu }}</h2>
        <div v-html="news.bodyRu"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsResource from "../services/NewsResource";
const news = new NewsResource();

export default {
  name: "news-page",
  data() {
    return {
      news: null,
    };
  },
  mounted() {
    this.loadArticle()
  },
  methods: {
    async loadArticle() {
      this.news = await news.getArticle("9EA7564A-B0C8-4FBB-8529-D56E8F011988")
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 40px;
}
.news {
  background-color: #fff;
  padding: 20px 40px;
}
</style>